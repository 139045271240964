<template>
  <v-app>
    <router-view />
  </v-app>
</template>
<script>
import "@/assets/css/fonts.css";
import "@/assets/css/global.css";
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style></style>
