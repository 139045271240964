<template>
  <div>
    <v-img src="@/assets/images/product/biotics.jpg" alt="씬바이오틱스" />
  </div>
</template>

<script>
export default {
  name: "ProductView",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style scoped lang="scss"></style>
